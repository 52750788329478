import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './BrandSwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link } from "react-router-dom";
import { handleImageLinkDrage, marqueImgUrl, marqueLogoUrl } from "../../../helpers/Helpers";
import { MarqueProps } from "../../../helpers/Props";
import { useWindowWidthContext } from "@contexts/WindowWidthContext";

interface BrandSwiperProps {
    page: string
    brands: MarqueProps[];

}

const BrandSwiper: React.FC<BrandSwiperProps> = ({ brands, page }) => {
    const { windowWidth } = useWindowWidthContext()

    let screen = 4

    if (windowWidth < 1400 && windowWidth >= 1200) {
        screen = 4
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 3
    } else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 2
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 2
    } else if (windowWidth < 578) {
        screen = 1
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center brand-navigation brandswiper">
                    <Swiper
                        className="mySwiper"
                        slidesPerView={screen}
                        modules={[Autoplay, Pagination, Navigation]}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={false}
                        navigation={windowWidth > 1200 ? true : true}
                    >
                        {brands.map((item: MarqueProps, index) => (
                            <SwiperSlide key={item.id} className='d-flex-center '>
                                <div className="brand-swiper-content d-flex align-brands-center justify-content-center mxc-5" key={item.id}>
                                    <div className="brand-swiper d-flex-center">
                                        <Link to={`/boutique/${page}marque/${item.slug}`} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                                            <img
                                                src={`${marqueLogoUrl}/${item?.logo}`}
                                                className={`brand-swiper-logo w-${index === 0 ? '60' : index === 1 ? '65' : '75'}`}
                                                alt={item?.title}
                                                onDragStart={handleImageLinkDrage}
                                            />
                                            <img
                                                src={`${marqueImgUrl}/${item?.image}`}
                                                className="brand-swiper-img"
                                                alt={item?.title}
                                                onDragStart={handleImageLinkDrage}
                                            />

                                            <div className="brand-swiper-overlay d-flex-center c-pointer text-center">
                                            </div>
                                            <div className="brand-swiper-overlay-before d-flex-center c-pointer text-center">
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="brand-swiper-title mt-3 d-non align-brands-center justify-content-center">
                                        <p className='brand-swiper-title ff-lato-reg fw-600'>{item?.title}</p>
                                    </div>

                                    {/* <div className="brand-swiper-title mt-3 d-non align-brands-center justify-content-center">
                                        <p className='brand-swiper-title ff-varela-round fw-600'>{item?.title}</p>
                                    </div> */}

                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default BrandSwiper;