import { FC } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import { TruncateText, packImgUrl } from '@helpers/Helpers';
import Countdown from 'react-countdown';
import './PackCardStyle.css'

type PackCardProps = {
  item: any;
  openModal?: (product: any) => void;
  isGrid?: boolean;

};

const PackCard: FC<PackCardProps> = ({ item, isGrid }) => {
  const { addToCart } = useShoppingCart();
  const location = useLocation();
  const currentPath = location.pathname;
  const packPath = '/boutique/packs';

  const Completionist = () => <span>Expiré!</span>;
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed && hours === 0 && minutes === 0) {
      return <Completionist />;
    } else {
      return <span>{`${!isGrid ? 'Expiré :' : ''}`} {days}j : {hours}h : {minutes}m : {seconds}s</span>;
    }
  };

  return (
    <div className={`col-md-6 col-lg-6 col-xl-4 col-xxl-3 col-sm-6 ${isGrid ? 'col-6 isgrid' : 'col-12 isrow'} pack-item`}>
      <div className="container">
        <div className="row d-flex-center">
          <div className="pack-time text-center">

            <span className='position-fixed time-down ff-varela-round'>
              <Countdown
                date={item.dateFin}
                renderer={renderer}
              /></span>

          </div>
          <div className={`pack-image text-center p-0 ${currentPath === packPath ? 'fixed-style' : ''}`}>
            <Link to={`/boutique/pack/${item.slug}`}>
              <img alt={item.title} src={`${packImgUrl}/${item.image}`} className="pack-img mb-5x" />
            </Link>
          </div>
          <div className="text-center">
            <div className="d-none eye d-flex-center text-center">
              <div className="view d-flex-center">
                <AiOutlineEye className="eye-icon" />
              </div>
            </div>
            <div className="pack-details text-center">
              <div className="pack-info">
                <Link className='nav-link' to={`/boutique/pack/${item.slug}`}>
                  <div className="pack-title">{<TruncateText text={item.title} maxLength={55} />}</div>
                </Link>

                <div className="add-to-cart-container fixed-bottom row justify-content-between align-items-center mb-1">
                  <hr className='hr-tag m-hr' />

                  <div className="col-4 ps-0 text-start">
                    <div className="pack-price fixedbottom clr-pink ff-varela-round fw-600">
                      <span>{item.sellingPrice} DH</span>
                    </div>
                  </div>

                  <div className="col-8 pe-0 text-end shopping-cart-add-to d-flex justify-content-end ">
                    <div
                      // className="shopping-cart-icon-parent d-flex-center c-pointer"
                      className="addtocart-parent d-flex-center p-0 c-pointer ff-varela-round"
                      onClick={() => addToCart(item.uniqId, item.sellingPrice, 'pack')}
                    >
                      Ajouter au panier
                      {/* <MdShoppingCart className='shopping-cart-icon' /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackCard;