import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { categorieImgUrl, isEven } from '@helpers/Helpers';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useCategoriesContext } from '@contexts/CategoriesContext';
import { HeaderContainer } from '@helpers/Index';
import Layout from '@layouts/Layout';
import './DetailsCategories.css'

const DetailsCategories: React.FC = () => {

    const { categories } = useCategoriesContext();
    const { windowWidth } = useWindowWidthContext()

    return (

        <Layout>
            <Helmet>
                <title>Categories</title>
            </Helmet>

            <HeaderContainer
                className="fw-bold mt-5 contact-title"
                title='Nos Categories'
            />

            <div className="container details-categories-container">
                {
                    categories?.map((item, index) => (
                        <React.Fragment key={item.slug}>
                            <div className="row details-categories-content" >

                                {
                                    windowWidth > 992 ?
                                        isEven(index) ?
                                            <>
                                                <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-start">
                                                    <div className="details-categories-image">
                                                        <Link to={`/boutique/categorie/${item.slug}`}>
                                                            <img
                                                                src={`${categorieImgUrl}/${item.image}`}
                                                                alt={item.title}
                                                                className='details-categories-img' />
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                                    <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                        <h2 className='top-image-desc ff-outfit-m fw-600'>{item.title}</h2>
                                                    </div>
                                                    <div className="ff-varela-round fw-600 details-categories-desc">
                                                        {item.description}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                                    <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                        <h2 className='top-image-desc ff-outfit-m fw-600'>{item.title}</h2>
                                                    </div>
                                                    <div className="ff-varela-round fw-600  details-categories-desc">
                                                        {item.description}
                                                    </div>
                                                </div>

                                                <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-end ">
                                                    <div className="details-categories-image">
                                                        <Link to={`/boutique/categorie/${item.slug}`}>
                                                            <img
                                                                src={`${categorieImgUrl}/${item.image}`}
                                                                alt={item.title}
                                                                className='details-categories-img' />
                                                        </Link>
                                                    </div>
                                                </div>


                                            </>
                                        :
                                        <>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-start details-categories-col-img">
                                                <div className="details-categories-image">
                                                    <Link to={`/boutique/categorie/${item.slug}`}>
                                                        <img
                                                            src={`${categorieImgUrl}/${item.image}`}
                                                            alt={item.title}
                                                            className='details-categories-img' />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                                <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                    <h2 className='top-image-desc ff-outfit-m fw-600'>{item.title}</h2>
                                                </div>
                                                <div className="ff-varela-round fw-600 details-categories-desc">
                                                    {item.description}
                                                </div>
                                            </div>
                                        </>
                                }

                            </div>

                            <hr className='hr-tag' />
                        </React.Fragment>
                    ))
                }
            </div>
        </Layout>
    );
};

export default DetailsCategories;
