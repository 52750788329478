import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useCategoriesContext } from '@contexts/CategoriesContext';
import { FooterLink } from '@helpers/Helpers';
import NewsEmail from '../NewsEmail/NewsEmail';
import Icons from '@helpers/IconsHelper';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import './FooterStyle.css'

interface FooterProps {
  footerClass?: string
}

const Footer: FC<FooterProps> = ({ footerClass = '' }) => {
  const { categories } = useCategoriesContext();
  const [showCategorie, setShowCategorie] = useState(false);
  const [showIndfo, setShowIndfo] = useState(false);
  const { windowWidth } = useWindowWidthContext()

  return (
    <>
      <MDBFooter className={`text-center text-lg-start text-muted bg-footer ff-lato-reg clr-blue ff-lato-reg00 mt-2rem ${footerClass}`}>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt5 pt-2'>
            <MDBRow className='mt-3'>
              <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                <h6 className='text-uppercase clr-blue ff-lato-reg mb-4'>
                  {/* <MDBIcon color='secondary' icon='gem' className='' /> */}
                  Beauty Hub
                </h6>
                <p className='d-non ff-varela-round'>
                  Inscrivez-vous et devenez une
                  #BeautyhubBabe
                </p>
                <NewsEmail />

              </MDBCol>

              <MDBCol md='2' lg='2' xl='2' className={`mx-auto mb-${windowWidth > 640 ? '4' : '2'}`}>
                <h6 className={`text-uppercase clr-blue ff-lato-reg mb-${windowWidth > 640 ? '4' : '2'} c-pointer`} onClick={() => {
                  setShowCategorie(!showCategorie)
                  setShowIndfo(false)
                }}>CATÉGORIES  {windowWidth > 578 ? '' : <Icons.MdExpandMore className='clr-dark pb-1 fs-25px' />}</h6>
                {windowWidth > 578 || showCategorie ?
                  categories.map((item) => (
                    <p key={item.id} className='mb-1'>
                      <Link to={`/boutique/categorie/${item.slug}`} className='ff-lato-reg textpink-hover nav-link'>
                        {item.title}
                      </Link>
                    </p>
                  ))
                  :
                  null
                }
              </MDBCol>

              <MDBCol md='3' lg='2' xl='2' className={`mx-auto mb-${windowWidth > 640 ? '4' : '2'}`}>
                <h6 className={`text-uppercase clr-blue ff-lato-reg mb-${windowWidth > 640 ? '4' : '2'} c-pointer`} onClick={() => {
                  setShowIndfo(!showIndfo)
                  setShowCategorie(false)
                }}>Informations {windowWidth > 578 ? '' : <Icons.MdExpandMore className='clr-dark pb-1 fs-25px' />}</h6>

                {windowWidth > 578 || showIndfo ?
                  <>
                    {/* <FooterLink to='/blogs' label='Blogs' /> */}
                    {/* <FooterLink to='/boutique/packs' label='Packs' /> */}
                    <FooterLink to='/about-us' label='A propos de Beauty Hub' />
                    <FooterLink to='/conditions-generales' label='Condition générales' />
                    <FooterLink to='/livraison' label='Livraison' />
                    <FooterLink to='/contact' label='Contact' />
                  </>
                  :
                  null
                }
              </MDBCol>

              <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase clr-blue ff-lato-reg mb-4'>Contact</h6>
                <p>
                  <Icons.FaLocationDot className='text-dark mb-1 me-1' />
                  Casablanca, bd abdelmoumen, abdelmoumen center angle
                </p>
                <p>
                  <Icons.MdEmail className='text-dark mb-1 me-1' />
                  Hello@beautyhub.ma
                </p>
                <p>
                  <Icons.ImPhone className='text-dark mb-1 me-1' />
                  0600456348
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className={`text-center text-light p-3 copy-right-bg`}>
          © {new Date().getFullYear()} Copyright
          <Link className='text-reset ps-1' to='/'>
            Beauty Hub
          </Link>
        </div>
      </MDBFooter>
    </>
  );
};

export default Footer;