import React, { memo, useCallback, useEffect, useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import useApiRequest from "@mbs-dev/api-request";
import { CartItemProps } from '@helpers/Props';
import { useShoppingCartDataContext } from '@contexts/ShoppingCartDataContext';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import './OrderItem.css'
import { apiUrl } from '@helpers/Helpers';


const OrderItem: React.FC<CartItemProps> = ({ product, quantity, price }) => {

  const { products, packs } = useShoppingCartDataContext();

  const { apiRequest } = useApiRequest();
  const [discount, setDiscount] = useState(null);

  const mergedArray = [...products, ...packs];

  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)


  const fetchData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        if (response.status === 200) {
          setDiscount(response.data.discount)
        } else {
          FrCustomeErrorNorify()
        }
      }

    } catch (error) {

    }
  }, [item?.id]);


  // useEffect(() => {
  //   fetchData();
  // }, [item?.id])

  if (item == null) return null

  // let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;


  return (
    <>
      <div className="order-item d-flex justify-content-betdween align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center order-item-content">

            <div className="col-8 d-flex align-items-center">
              <div className="order-item-details">
                <div className="order-item-title clr-blue">
                  <span className='clr-pink'>{quantity}</span> <GrFormClose className='subshopping-cart-close-icon' />
                  {item.title}</div>
              </div>
            </div>

            <div className="col-4 d-flex align-items-center">
              <div className="order-item-total align-items-center">
                {/* <span className="align-items-center px-2 ms-4 clr-dark">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : (productPrice * quantity).toFixed(2)} DH</span> */}
                <span className="align-items-center px-2 ms-4 clr-dark">{(price * quantity).toFixed(2)} DH</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <hr className='hr-tag' />
    </>
  );
};

const MemoizedOrderItem = memo(OrderItem);

export { MemoizedOrderItem as OrderItem };