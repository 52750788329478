import React, { createContext, useContext, useEffect, useState } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { CategorieProps } from '@helpers/Props';
import { apiUrl } from '@helpers/Helpers';


const CategoriesContext = createContext<{
    categories: CategorieProps[];
}>({
    categories: []
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<CategorieProps[]>([]);
    const { apiRequest } = useApiRequest();

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/parents-categories`,
                method: 'GET',
            });

            setCategories(response.data)

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <CategoriesContext.Provider value={{ categories }}>
                {children}
            </CategoriesContext.Provider>
        </>

    );
};
