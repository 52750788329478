import { FC, useEffect, useState } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { ProductProps } from '@helpers/Props';
import { HeaderContainer, formHelper, hooks } from '@helpers/Index';
import { apiUrl } from '@helpers/Helpers';
import { Link } from 'react-router-dom';
import ProductSwiper from '../Carousel/Product/ProductItemMultiCarousel/ProductSwiper';
import './NewProducts.css'
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useQuery } from 'react-query';

const NewProducts: FC = () => {
  // const [products, setProducts] = useState<ProductProps[]>([]);
  const { apiRequest } = useApiRequest();
  const { windowWidth } = useWindowWidthContext()

  const fetchProductsData = async () => {
    try {
      const response = await formHelper.axios.get(`${apiUrl}/products.json`, {
        params: {
          disponibilite: 'enstock',
          latest: 'enable',
          page: 1,
          itemsPerPage: 8,
        },
      })

      if (response.status === 200) {
        // setProducts(response.data)
      }
    } catch (error) {

    }
  };


  const {
    data,
    isLoading: isFetching,
} = useQuery<ProductProps[], Error>(
    'NewProducts',
    async () => {
        const response = await apiRequest({
            route: `${apiUrl}/products.json`,
            method: 'GET',
            params: {
              disponibilite: 'enstock',
              latest: 'enable',
              page: 1,
              itemsPerPage: 8,
            }
        });
        return response.data;

    }
);

const products = data || []

// console.log(products);
  // useEffect(() => {
  //   fetchProductsData();
  // }, [])

  return (
    <>
      {products.length > 0 ?
        <div className="productss populare-products">
          <div className="container">
            <HeaderContainer className={`fw-bold pt-${windowWidth > 768 ? '3' : '0'} fs-37px ff-outfit-semi`} title="NOS NOUVEAUTÉS" />
            <div className="row">
              <div className="col-xl-6col-lg-6col-md-12 col-12 ">
                <ProductSwiper items={products} itemType='product' />

              </div>
              <div className="d-flex justify-content-center">
                <Link to='/boutique/latest' className="btn-loading mt-2 p-0 d-flex-center" >
                  Voir plus
                </Link>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  );
};

export default NewProducts;