import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import useApiRequest from "@mbs-dev/api-request";
import { CartItemProps } from '@helpers/Props';
import { useShoppingCartDataContext } from '@contexts/ShoppingCartDataContext';
import { TruncateText, apiUrl, uploadUrl, variantIconUrl } from '@helpers/Helpers';
import Icons from '@helpers/IconsHelper';
import './SubShoppingCartStyle.css'

const SubShoppingCart: FC<CartItemProps> = ({ product, quantity, isvariant, variantId, price, itemKey }) => {

  const { removeFromCart } = useShoppingCart()
  const { apiRequest } = useApiRequest();
  const [discount, setDiscount] = useState(null);
  const { products, packs } = useShoppingCartDataContext();
  const [variantPrice, setVariantPrice] = useState<number>();
  const [isVariant, setIsVariant] = useState(false);

  const mergedArray = [...products, ...packs];
  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        setDiscount(response.data.discount)
      }

    } catch (error) {

    }
  }, [item?.id]);


  // useEffect(() => {
  //   fetchData();
  // }, [item?.id])

  let variantInfo: any
  if (item?.variant && item.variant.length > 0) {
    variantInfo = item.variant.find(i => i.id === variantId)
  }

  useEffect(() => {
    if (discount !== null && item?.variant && item?.variant?.length > 0) {
      let sellingPrice = variantInfo?.sellingPrice
      if (sellingPrice !== item?.sellingPrice) {
        setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
        setIsVariant(true)
      }
    }
  }, [item?.id, variantInfo?.id])

  if (item == null) return null

  // let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;



  return (
    <>
      <div className="subshopping-cart-item d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-3 col-lg-3 col-sm-3 col-3 h-100">
              <img src={`${uploadUrl}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="subshopping-cart-item-img" />
            </div>
            <div className="col-md-8 col-lg-8 col-sm-8 col-8">
              <div className="subshopping-cart-item-details">
                <div className="subshopping-cart-item-title align-items-center text-cap">
                  <span className='subshopping-cart-item-quantity clr-pink'>
                    {quantity}
                  </span> <Icons.GrFormClose className='subshopping-cart-close-icon' /> {<TruncateText text={item.title} maxLength={28}/>}
                </div>
                {
                  isvariant ?
                    <div className="d-flex ai-center">
                      <div className={`subvariant-icon-content mx-1 d-flex-center c-pointer selected-border`} >
                        <div className="subvariant-icon d-flex-center">
                          <img src={`${variantIconUrl}/${variantInfo?.icon}`} alt={variantInfo?.label} className='variant-icon-img' />
                        </div>
                      </div>
                      <div className="subvariant-title ff-varela-round fw-600">
                        {`${variantInfo?.label}`}
                      </div>
                    </div>
                    :
                    null
                }
                <div className="subshopping-cart-item-prix">
                  {/* {item.itemType === 'pack' ?
                    isvariant && variantPrice ?
                      (variantPrice * quantity).toFixed(2)
                      :
                      item.sellingPrice.toFixed(2)
                    : (productPrice * quantity).toFixed(2)} */}
                  {price?.toFixed(2)}
                  DH</div>
              </div>
            </div>
            <div className="col-md-1 col-lg-1 col-sm-1 col-1 d-flex align-items-center">
              <div className="trash-icon">
                <Icons.BsFillTrashFill className="trash" onClick={(() => removeFromCart(item.itemType === 'pack' ? item.uniqId : item.id, itemKey))} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MemoizedSubShoppingCart = memo(SubShoppingCart);
export { MemoizedSubShoppingCart as SubShoppingCart };