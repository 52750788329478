import React, { createContext, useContext, useState, useEffect } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { PackProps, ProductProps } from '@helpers/Props';
import { useWishlist } from './WishlistContext';
import { apiUrl } from '@helpers/Helpers';

const WishlistDataContext = createContext<{
    packs: PackProps[];
    products: ProductProps[];
}>({
    packs: [],
    products: []
});

export const useWishlistDataContext = () => useContext(WishlistDataContext);

export const WishlistDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { wishlistItems } = useWishlist()
    const { apiRequest } = useApiRequest();

    const [products, setProducts] = useState<ProductProps[]>([]);
    const [packs, setPacks] = useState<PackProps[]>([]);
    const productIds: any[] = [];
    const packIds: any[] = [];

    wishlistItems.map((i: any) => {
        if (i.itemtype === 'pack') {
            packIds.push(i.product);
        }
        else if (i.itemtype === 'product') {
            productIds.push(i.product);
        }
    })

    const fetchWishlistData = async () => {

        try {
            if (productIds.length > 0) {
                const response = await apiRequest({
                    route: `${apiUrl}/products-by-ids`,
                    method: 'POST',
                    data: productIds
                });

                if (response.status === 200) {
                    setProducts(response.data)
                }
            }

            if (packIds.length > 0) {
                const packResponse = await apiRequest({
                    route: `${apiUrl}/packs-by-ids`,
                    method: 'POST',
                    data: packIds
                });

                if (packResponse.status) {
                    setPacks(packResponse.data)
                }
            }

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchWishlistData();
    }, [wishlistItems])

    return (
        <>
            <WishlistDataContext.Provider value={{ packs, products }}>
                {children}
            </WishlistDataContext.Provider>
        </>
    );
};
