import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserDashboard } from '../UserDashboard/UserDashboard';
import { AuthUserProps, ReviewsProps } from '@helpers/Props';
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl, formatDate } from '@helpers/Helpers';
import { Helmet } from 'react-helmet-async';
import { FrCustomeErrorNorify, DeleteNotify, UpdateNotify, ErrorNotify } from '@helpers/Toastify';
import { formHelper, loadingHelper } from '@helpers/Index';
import Icons from '@helpers/IconsHelper';


const Content: React.FC = () => {

    const [reviews, setReviews] = useState<ReviewsProps[]>([]);
    const { apiRequest } = useApiRequest();
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const [reviewMessages, setReviewMessages] = useState<{ [key: number]: string }>({});
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const storedUser = localStorage.getItem('bh_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const fetchData = useCallback(async () => {
        try {
            if (user?.id) {
                setIsLoading(true)
                const response = await apiRequest({
                    route: `${apiUrl}/user-reviews/user/${user?.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('bh_user_token')}`
                });

                if (response.status === 200) {
                    setReviews(response.data.reviews)
                    setIsLoading(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }

        } catch {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }, [user?.id, apiRequest]);


    useEffect(() => {
        fetchData()
    }, [user?.id])

    useEffect(() => {
        const initialMessages: { [key: number]: string } = {};
        reviews.forEach((item) => {
            initialMessages[item.id] = item.message || '';
        });
        setReviewMessages(initialMessages);
    }, [reviews]);


    const EditReview = async (reviewId: number) => {
        try {
            const requestData = {
                message: reviewMessages[reviewId],
                status: 'pending'
            };

            if (requestData.message !== '') {
                const response = await apiRequest({
                    route: `${apiUrl}/reviews/${reviewId}`,
                    method: 'PUT',
                    data: requestData,
                    headers: {
                        'Accept': 'application/ld+json',
                        'Content-Type': 'application/json'
                    },
                    requiresAuth: true,
                    token: `${localStorage.getItem('bh_user_token')}`,

                });

                if (response.status === 200) {
                    UpdateNotify('Avi');
                    fetchData();
                }
                else {
                    ErrorNotify('le message est vide!')
                }
            }
        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    const DeleteReview = async (reviewId: number) => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/reviews/${reviewId}`,
                method: 'DELETE',
                requiresAuth: true,
                token: `${localStorage.getItem('bh_user_token')}`
            });

            if (response.status === 204) {
                DeleteNotify('Avi');
                fetchData();
            } else {
                FrCustomeErrorNorify()
            }

        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    return (
        <>
            <Helmet>
                <title>Avis</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 bm-ms-4 newold">
                {
                    isLoading ?
                        <loadingHelper.AnimatedLoading label='Les Avis' className='col-12' />
                        :
                        <div className="account-order ms-4 bm-me-3">

                            {
                                reviews.length > 0 ?

                                    <div className="order-content edit-form oy-scroll">

                                        <table className='table align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4 min-w-125px'>Produit / Pack</th>
                                                    <th className='min-w-200px'>Message</th>
                                                    <th className='min-w-100px'>Date Ajoute</th>
                                                    <th className='min-w-100px'>Status</th>
                                                    <th className=' text-center rounded-end pe-5'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    reviews.map((item) => {
                                                        const reviewMessage = reviewMessages[item.id] || '';

                                                        return (
                                                            <tr key={item.id}>

                                                                <td className='td-title td-min-w'>
                                                                    <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.product ? item.product?.title : item.pack?.title}
                                                                    </span>
                                                                </td>
                                                                <td className='td-min-w'>
                                                                    <formHelper.FormInput
                                                                        name="message"
                                                                        textarea={true}
                                                                        rows={2}
                                                                        value={reviewMessage}
                                                                        handleChange={(e) => {
                                                                            setReviewMessages((prevMessages) => ({
                                                                                ...prevMessages,
                                                                                [item.id]: e.target.value
                                                                            }));
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {formatDate(item.dateCreate)}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.status === 'pending' ?
                                                                            <span className='bm-badge-primary bm-badge'>
                                                                                En attente
                                                                            </span>
                                                                            :
                                                                            item.status === 'refused' ?
                                                                                <span className='bm-badge-danger bm-badge'>
                                                                                    Refusé
                                                                                </span>
                                                                                :
                                                                                <span className='bm-badge-success bm-badge'>
                                                                                    Publié
                                                                                </span>

                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className='text-end'>

                                                                    <button className='btn bm-small-btn bm-primary-btn me-2' onClick={() => EditReview(item.id)}>
                                                                        <Icons.MdModeEditOutline className='bm-icon bm-primary-icon' />
                                                                    </button>
                                                                    <button className='btn bm-small-btn bm-danger-btn' onClick={() => DeleteReview(item.id)}>
                                                                        <Icons.MdDelete className='bm-icon bm-danger-icon' />
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        );
                                                    })

                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                    :
                                    ''
                            }

                            {reviews.length === 0 ?
                                <div className="order-message d-flex-center">
                                    <span>
                                        <Link to='/boutique' className='nav-link d-inline goto-boutique'>
                                            <Icons.BsChatRightDots className='order-message-icon' /> PARCOURIR LES PRODUITS
                                        </Link>
                                        <span className='account-notif'> | Aucune avis.</span>
                                    </span>
                                </div>
                                :
                                null
                            }

                        </div>
                }

            </div>
        </>
    );
};


const UserReviews: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { UserReviews };