import React from 'react';
import HeaderContainer from '@components/HeaderContainer/HeaderContainer';
import { Helmet } from 'react-helmet-async';
import Layout from '../../layouts/Layout';
import Icons from '@helpers/IconsHelper';

const AboutUs: React.FC = () => {

    return (
        <Layout footerClass='fixed-bottom'>
            <Helmet>
                <title>Beauty Hub - Conditions Générales De Vente</title>
            </Helmet>
            <HeaderContainer
                className="ff-lato-bold fs-1 mt-5 "
                title='A propos de Beauty Hub'
            />

            <div className="container">
                <div className="row d-flex-center">
                    <div className="col-12 terms d-flex-center">
                        <div>
                            <p>
                                Beauty hub est l'expérience unique, où la diversité et la qualité se rejoignent pour sublimer votre routine beauté.
                            </p>
                            <p>
                                Découvrez notre large gamme de produits choisis parmi les marques les plus reconnues et les dernières tendances du make-up, soin peau et soin capillaire.
                            </p>
                            <p>
                                Bénéficiez de conseils personnalisés et d'offres exclusives pour créer une expérience d'achat unique qui va au-delà du shopping en ligne traditionnel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    );
};

export default AboutUs;
