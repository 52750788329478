import React, { ReactNode } from 'react'
import { Footer, HeaderContainer, Navbar, TopHeader } from '@helpers/Index'
import { ReactHelmet } from '@helpers/Helpers'

interface LayoutProps {
    children: ReactNode
    helmetTitle?: string
    containerTitle?: string
    className?: string
    footerClass?: string
}

const Layout: React.FC<LayoutProps> = ({ children, helmetTitle, containerTitle, className, footerClass }) => {
    return (
        <React.Fragment>
            {/* <ReactHelmet title={helmetTitle} />
            <HeaderContainer className={className} title={containerTitle} /> */}
            <TopHeader />
            <Navbar />
            <main>{children}</main>
            <Footer footerClass={footerClass} />
        </React.Fragment>
    )
}

export default Layout