import ReactLoading from "react-loading";
import './loadingStyle.css'
import { logo } from "../../helpers/Index";

const Loading: React.FC = () => {

    return (
        <>
            <div className="d-flex-center loader-parent" style={{ height: '100vh' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex-center">
                            <ReactLoading type='spinningBubbles' className="d-none" color='var(--clr-light)' height={130} width={90} />
                            <img src={logo} alt="" className='lazy-loading-img mb-4' />
                        </div>
                        <div className="col-12 d-flex-center">
                            <h2 className="clr-pink ff-lato-reg loading-text">Chargement...</h2>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loading;