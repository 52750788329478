import React, { createContext, useContext, useState, useEffect } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from '@helpers/Helpers';

interface Marque {
    id: number;
    title: string;
    description: string;
    slug: string;
    logo: string;
    image: string;
}

const MarquesContext = createContext<{
    marques: Marque[];
}>({
    marques: []
});

export const useMarquesContext = () => useContext(MarquesContext);

export const MarquesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [marques, setMarques] = useState<Marque[]>([]);

    const { apiRequest } = useApiRequest();

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/marques.json`,
                method: 'GET',
            });

            setMarques(response.data)
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <MarquesContext.Provider value={{ marques }}>
                {children}
            </MarquesContext.Provider>
        </>
    );
};
