import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MdOutlineExpandMore } from 'react-icons/md';
import './ControlledAccordions.css'

interface ControlledAccordionProps {
    title: any
    id: any
    content: any | React.ReactNode
}

const ControlledAccordions: React.FC<ControlledAccordionProps> = ({ title, id, content }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion className='css-muiroot ff-varela-round' expanded={expanded === `${id}`} onChange={handleChange(`${id}`)}>
                <AccordionSummary
                    expandIcon={<MdOutlineExpandMore />}
                    aria-controls={`${id}bh-content`}
                    id={`${id}bh-header`}
                >
                    <Typography className='clr-blue ' sx={{ width: '100%', flexShrink: 0 }}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {content}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default ControlledAccordions;