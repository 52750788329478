import { FC, Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DecodeData, ReactHelmet, TruncateText, apiUrl, blogImgUrl, decodeHtmlTags, isEven } from '@helpers/Helpers'
import { HeaderContainer,  formHelper } from '@helpers/Index'
import { BlogProps } from '@helpers/Props'
import { FrCustomeErrorNorify } from '@helpers/Toastify'
import { Pagination } from '@mui/material'
import Layout from '../../../../layouts/Layout'
import './BlogsList.css'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'

const BlogsList: FC = () => {

    const { windowWidth } = useWindowWidthContext()
    const [blogs, setBlogs] = useState<BlogProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const response = await formHelper.axios.get(`${apiUrl}/blogs.jsonld`, {
                params: {
                    page: currentPage,
                    itemsPerPage: 10,
                },
                headers: {
                    Accept: 'application/ld+json'
                }
            });
            if (response.status === 200) {
                setBlogs(response.data['hydra:member']);
                setTotalItems(response.data['hydra:totalItems']);
                setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    }

    useEffect(() => {
        fetchData();
    }, [currentPage, totalItems])


    return (

        <Layout>
            <ReactHelmet title='Articles de Blog' />
            <HeaderContainer
                className="fw-bold mt-5 contact-title"
                title='Articles de Blog'
            />

            <div className="container blog-container ox-hidden">
                {blogs?.map((item, index) => {
                    const decode = <DecodeData content={item.description} />
                    const desc = <TruncateText text={decode.props.content} maxLength={50} />

                    return (
                        <Fragment key={item.slug}>

                            <div className="row blog-content" >
                                {windowWidth > 992 ?
                                    isEven(index) ?
                                        <>
                                            <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-start">
                                                <div className="blog-image">
                                                    <Link to={`/blog/${item.slug}`} state={item}>
                                                        <img
                                                            src={`${blogImgUrl}/${item.image}`}
                                                            alt={item.title}
                                                            className='blog-img' />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                                <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                    <Link to={`/blog/${item.slug}`} className='bm-link'>
                                                        <h2 className='top-image-desc ff-outfit-m fw-600  c-pointer'>{item.title}</h2>
                                                    </Link>
                                                </div>
                                                <div className="ff-varela-round fw-600 blog-desc o-hidden">
                                                    <p
                                                        className={`product-details-desc ff-varela-round`}
                                                        dangerouslySetInnerHTML={{ __html: decodeHtmlTags(desc.props.text.replaceAll('\\n', "<br>")) }}>
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                                <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                    <Link to={`/blog/${item.slug}`} className='bm-link'>
                                                        <h2 className='top-image-desc ff-outfit-m fw-600  c-pointer'>{item.title}</h2>
                                                    </Link>
                                                </div>
                                                <div className="ff-varela-round fw-600  blog-desc o-hidden">
                                                    <p
                                                        className={`product-details-desc ff-varela-round`}
                                                        dangerouslySetInnerHTML={{ __html: decodeHtmlTags(desc.props.text.replaceAll('\\n', "<br>")) }}>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-end ">
                                                <div className="blog-image">
                                                    <Link to={`/blog/${item.slug}`}>
                                                        <img
                                                            src={`${blogImgUrl}/${item.image}`}
                                                            alt={item.title}
                                                            className='blog-img' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    :
                                    <>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0 d-flex-start blog-col-img">
                                            <div className="blog-image">
                                                <Link to={`/blog/${item.slug}`}>
                                                    <img
                                                        src={`${blogImgUrl}/${item.image}`}
                                                        alt={item.title}
                                                        className='blog-img' />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="h-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-3 col-12">
                                            <div className="d-non align-items-start justify-content-start mb-5 mt-4">
                                                <Link to={`/blog/${item.slug}`} className='bm-link'>
                                                    <h2 className='top-image-desc ff-outfit-m fw-600  c-pointer'>{item.title}</h2>
                                                </Link>
                                            </div>
                                            <div className="ff-varela-round fw-600 blog-desc o-hidden">
                                                <p
                                                    className={`product-details-desc ff-varela-round`}
                                                    dangerouslySetInnerHTML={{ __html: decodeHtmlTags(desc.props.text.replaceAll('\\n', "<br>")) }}>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <hr className='hr-tag' />
                        </Fragment>
                    );
                })}
            </div>
            {blogs.length > 10 ?
                <div className="row d-flex-center mb-5">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        color='primary'
                        onChange={handlePageChange}
                        className='d-flex justify-content-center'
                    />
                </div>
                :
                null

            }
        </Layout>
    )
}

export default BlogsList
