import { FC, createContext, useContext, useState, useEffect } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { useShoppingCart } from './ShoppingCartContext';
import { PackProps, ProductProps } from '@helpers/Props';
import { apiUrl } from '@helpers/Helpers';

const ShoppingCartDataContext = createContext<{
    packs: PackProps[];
    products: ProductProps[];
}>({
    packs: [],
    products: []
});

export const useShoppingCartDataContext = () => useContext(ShoppingCartDataContext);

export const ShoppingCartDataProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { cartItems } = useShoppingCart()
    const { apiRequest } = useApiRequest();

    const [products, setProducts] = useState<ProductProps[]>([]);
    const [packs, setPacks] = useState<PackProps[]>([]);
    const productIds: any[] = [];
    const packIds: any[] = [];

    cartItems.map((i: any) => {
        if (i.itemtype === 'pack') {
            packIds.push(i.product);
        }
        else if (i.itemtype === 'product') {
            productIds.push(i.product);
        }
    })

    const fetchShoppingCartData = async () => {
        try {
            if (productIds.length > 0) {
                const response = await apiRequest({
                    route: `${apiUrl}/products-by-ids`,
                    method: 'POST',
                    data: productIds
                });

                if (response.status === 200) {
                    setProducts(response.data)
                }
            }

            if (packIds.length > 0) {
                const packResponse = await apiRequest({
                    route: `${apiUrl}/packs-by-ids`,
                    method: 'POST',
                    data: packIds
                });

                if (packResponse.status) {
                    setPacks(packResponse.data)
                }
            }

        } catch (error) {
            // console.error(error);
        }
    };
    useEffect(() => {
        fetchShoppingCartData();
    }, [cartItems])

    return (
        <>
            <ShoppingCartDataContext.Provider value={{ packs, products }}>
                {children}
            </ShoppingCartDataContext.Provider>
        </>
    );
};
