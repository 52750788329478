import { FC } from 'react'
import { Link } from 'react-router-dom'
import { HeaderContainer } from '@helpers/Index'
import { AuthHeaderProps, AuthLinkProps } from '@helpers/Props'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'

const AuthHeader: FC<AuthHeaderProps> = ({ isLogin, isRegister }) => {
    const { windowWidth } = useWindowWidthContext()

    const BHLink = ({ is, to, label }: AuthLinkProps) => (
        <Link className={`nav-link d-flex-center sign-in-up ff-lato-reg ${is ? 'inactive-form' : 'active-form'} ${windowWidth > 578 ? '' : 'fs-5'}`} to={to}>
            {label}
        </Link>
    )
    return (
        <HeaderContainer
            className="fw-bold fs-1 mt-5"
            title={
                <div className="d-flex text-center justify-content-center">
                    <BHLink is={isRegister} to='/login' label={`Se Connecter`} />
                    <p className="mx-2 d-flex-center"></p>
                    <BHLink is={isLogin} to='/register' label={`S'Inscrire`} />
                </div>
            }
        />
    )
}

export default AuthHeader