import { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { SubShoppingCart } from '../Cart/SubShoppingCart/SubShoppingCart'
import {  emptyCart, freeShipping, hooks } from '@helpers/Index'
import { ProductProps } from '@helpers/Props'
import useApiRequest from "@mbs-dev/api-request";
import SearchBox from '../SearchBox/SearchBox'
import Icons from '@helpers/IconsHelper'
import './TopHeader.css'
import { useWishlist } from '@contexts/WishlistContext'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import { apiUrl } from '@helpers/Helpers'

const TopHeader: FC = () => {
    const { windowWidth } = useWindowWidthContext()
    const { cartItems, cartQuantity, total, emptyShoppingCart, updateCartItemsWithPromotions } = useShoppingCart()
    const { wishlistItems } = useWishlist()
    const cartItemsQuantity = cartItems.length
    const wishlistItemsQuantity = wishlistItems.length
    const { apiRequest } = useApiRequest();
    const { productIds, isProductIds } = hooks.useProductIds()

    // States
    const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false)
    const [searchBoxVisible, setSearchBoxVisible] = useState(false)
    const [products, setProducts] = useState<ProductProps[]>([])
    const [textValue, setTextValue] = useState('')

    // Use Click Outside
    const elementRef = useRef<HTMLDivElement>(null)
    const blockRef = useRef<HTMLDivElement>(null)

    const handleShoppingCartBoxClose = () => {
        setShoppingCartBoxVisible(false)
    }

    hooks.useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef)

    const handleShoppingCartIconClick = () => {
        setShoppingCartBoxVisible(!ShoppingCartBoxVisible)
    }

    useEffect(() => {
        updateCartItemsWithPromotions()
    }, [ShoppingCartBoxVisible])


    useEffect(() => {
        if (!searchBoxVisible) {
            setProducts([])
            setTextValue('')
        }
    }, [searchBoxVisible])

    const handleSearchIconClick = () => {
        setSearchBoxVisible(!searchBoxVisible)
    }

    const searchProducts = async (text: string) => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/products.json?title=${text}&disponibilite=enstock`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProducts(response.data)
            } else {
            }

        } catch (error) {
        }
    }


    return (
        <div className='top-header-container d-flex justify-content-between align-items-center'>
            <div className="containerr top-container w-100 mx5">
                <div className="row align-items-center mx-3">
                    <div className={`col-lg-3 col-md-3 col-sm-7 col-6 align-items-center ${windowWidth < 770 ? 'textcenter' : 'text-start d-inline-flex ps-4'} ${windowWidth < 578 ? 'dcontents' : ''} `}>
                        <div className="info d-inline-flex m-info mb-1">
                            <Link to='tel:0600456348' className={`nav-link top-header-text ff-varela-round  mt-sm-1 ${windowWidth < 578 ? 'me-2 mt-2' : 'me-4'}`}>
                                <Icons.ImPhone className='icon' /> 0600456348
                            </Link>
                        </div>
                    </div>

                    {windowWidth > 768 ?
                        <div className={`col-lg-6 col-md-6 col-sm-6 col-7 align-items-center ${windowWidth < 770 ? 'text-center' : 'text-center d-inline-fle ps4'}`}>
                            <div className="d-flex-center">
                                <div className="free-shipping">
                                    <img src={freeShipping} alt="free shipping" className='free-shipping-img me-2' />
                                </div>
                                <span className='clr-light text-cap'>Livraison gratuite à partir de 300 DH</span>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className={`col-lg-3 col-md-3 col-sm-5 col-6 mt-2 align-items-center time`}>
                        <div className="">
                            <div className={`social-media-conten`}>
                                <div className="row"  >
                                    <div className="col-12 text-end d-flex-end">
                                        {windowWidth > 992 ?
                                            <div className="me-3">
                                                <div onClick={handleSearchIconClick}>
                                                    <Icons.BsSearch className="top-header-icon me" />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="me-3">
                                            <Link to='/login' className='nav-link'>
                                                <Icons.HiOutlineUserCircle className="top-header-icon me" />
                                            </Link>
                                        </div>

                                        <div className="me-4">
                                            <Link to='/wishlist'><Icons.MdOutlineFavorite className="top-header-icon me" /></Link>
                                            <span className="position-absolute translate-middle badge card-badge rounded-circle z-index-9 bg-dark badge-card">
                                                {wishlistItemsQuantity}
                                            </span>
                                        </div>

                                        <div className="" onClick={handleShoppingCartIconClick} ref={blockRef}>
                                            <Icons.MdShoppingCart className="top-header-icon" />
                                            <span className="position-absolute translate-middle badge card-badge rounded-circle z-index-9 bg-dark badge-card">
                                                {cartItemsQuantity}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start shoppingCart box */}
            <div ref={elementRef} className={`subshopping-cart-box ${ShoppingCartBoxVisible ? 'show' : 'd-nones'}`}>
                <div className="container">
                    <div className="row card-content oy-scroll">
                        <div className="col-md-12 col-12 mb-3">
                            {cartQuantity < 1 ?
                                <>
                                    <div className="row">
                                        <div className="col-12 d-flex-center col-">
                                            <img src={emptyCart} alt="empty cart" className='subshopping-cart-empty-image' />
                                        </div>
                                        <div className="col-12 mt-3 d-flex-center col-">
                                            <h4 className='text-center'>Votre panier est actuellement vide.</h4>
                                        </div>
                                        <div className="col-12 mt-3 d-flex-center col-">
                                            <Link to='/boutique' className='nav-link back-to-boutique mb-3'>RETOUR À LA BOUTIQUE</Link>
                                        </div>
                                    </div>
                                </>
                                :
                                cartItems.map(item => (
                                    <SubShoppingCart key={item.product} variantId={item.variant} itemKey={item.ukey} {...item} />
                                ))
                            }
                        </div>
                    </div>

                    {cartQuantity > 0 && (
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="total-price text-center d-flex justify-content-between px-4">
                                    <span className="price-text mt-2">SOUS-TOTAL :</span>
                                    <span className="price-value mt-2">{total.toFixed(2)} DH</span>
                                </div>

                            </div>
                        </div>
                    )}

                </div>
                <div className="row">
                    {cartQuantity > 0 && (
                        <div className="mt-4 col-md-12 col-12 text-center card-checkout d-flex-center">
                            <div className='card-checkout-btn mb-2 nav-link ff-lato-reg clr-pink c-pointer text-cap' onClick={() => emptyShoppingCart()}>
                                <Icons.BsFillTrashFill className='card-checkout-icon' /> Vider mon panier
                            </div>
                        </div>
                    )}
                    <div className="subshopping-cart-message text-center c-pointer" >
                        <Link to='/my-cart ' className='nav-link clr-light'>
                            VOIR LE PANIER
                        </Link>
                    </div>
                </div>
            </div>
            {/* End shoppingCart box */}

            {/* Start Search box */}
            <SearchBox
                searchBoxVisible={searchBoxVisible}
                textValue={textValue}
                products={products}
                productIds={productIds}
                isProductIds={isProductIds}
                position='top'
                setTextValue={setTextValue}
                searchProducts={searchProducts}
            />
            {/* End Search box */}
        </div>
    )
}

export default TopHeader