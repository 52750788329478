import { FC } from 'react';
import { handleImageLinkDrage, marqueImgUrl, marqueLogoUrl } from '@helpers/Helpers';
import { Link } from 'react-router-dom';
import './MarquesList.css'
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useMarquesContext } from '@contexts/MarquesContext';

const MarquesList: FC = () => {
    const { marques } = useMarquesContext();

    const { windowWidth } = useWindowWidthContext()

    return (
        <>
            {marques.length > 0 ?
                <div className="marques-container my-5">
                    <div className="container">
                        <HeaderContainer className="fw-bold fs-37px ff-outfit-semi" title="NOS MARQUES" />

                        <div className="row d-flex-center">
                            {marques.map((item) => {
                                return (
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-6" key={item.id}>
                                        <div className='marque-card my-2'>
                                            <div className="marque-card-image d-flex-center">
                                                <Link to={`/boutique/marque/${item?.slug}`} className='nav-link w-100 h-100' onDragStart={handleImageLinkDrage}>
                                                    <div className="d-flex-center w-100">
                                                        <img
                                                            src={`${marqueLogoUrl}/${item?.logo}`}
                                                            className="marque-card-logo"
                                                            alt={item?.title}
                                                            onDragStart={handleImageLinkDrage}
                                                        />
                                                    </div>
                                                    <img
                                                        src={`${marqueImgUrl}/${item?.image}`}
                                                        className="marque-card-img"
                                                        alt={item?.title}
                                                        onDragStart={handleImageLinkDrage}
                                                    />

                                                    <div className="marque-card-overlay d-flex-center c-pointer text-center">
                                                    </div>
                                                    <div className="marque-card-overlay-before d-flex-center c-pointer text-center">
                                                    </div>
                                                </Link>

                                            </div>

                                            <div className="marque-card-desc text-center mt-2">
                                                <span className={`ff-outfit-m fs-${windowWidth < 578 ? '17' : '20'}px`}>{item.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default MarquesList;