import { FC } from 'react';
import { ProductImagesGalleryProps } from '@helpers/Props';
import { PMultiImgUrl, VMultiImgUrl } from '@helpers/Helpers';
import ImageGallery from 'react-image-gallery';
import './ProductImagesGalleryStyle.css'


// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';
// import './ProductImagesSwiper.css';
// import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

const ProductImagesGallery: FC<ProductImagesGalleryProps> = ({ product, isVariant = false, index = 0 }) => {

  // const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const productImages = product
    ? product.productImages.map(image => ({
      original: `${PMultiImgUrl}/${image.image}`,
      thumbnail: `${PMultiImgUrl}/${image.image}`,
    }))
    : [];

  // const variantImages = product
  //   ? product.variant[index].variantImages.map(image => ({
  //     original: `${VMultiImgUrl}/${image.image}`,
  //     thumbnail: `${VMultiImgUrl}/${image.image}`,
  //   }))
  //   : [];

  const variantImages = product && product.variant && product.variant[index]
    ? product.variant[index].variantImages.map(image => ({
      original: `${VMultiImgUrl}/${image.image}`,
      thumbnail: `${VMultiImgUrl}/${image.image}`,
    }))
    : [];


  if (!product) {
    return null;
  }

  return (
    <div>
      <ImageGallery
        items={isVariant ? variantImages : productImages}
        lazyLoad={true}
        thumbnailPosition='bottom'
      />
    </div>


    // <>
    //   <Swiper
    //     style={{
    //       '--swiper-navigation-color': '#fff',
    //       '--swiper-pagination-color': '#fff',
    //     } as React.CSSProperties & any}
    //     spaceBetween={10}
    //     navigation={true}
    //     thumbs={{ swiper: thumbsSwiper }}
    //     modules={[FreeMode, Navigation, Thumbs]}
    //     className="mySwiper2"
    //   >

    //     {
    //       isVariant ?
    //         product.variant[index].variantImages.map((item) => (
    //           <SwiperSlide>
    //             <img src={`${VMultiImgUrl}/${item.image}`} />
    //           </SwiperSlide>
    //         ))


    //         : product.productImages.map((item) => (
    //           <SwiperSlide>
    //             <img src={`${PMultiImgUrl}/${item.image}`} />
    //           </SwiperSlide>
    //         ))
    //     }


    //   </Swiper>
    //   <Swiper
    //     onSwiper={() => setThumbsSwiper}
    //     spaceBetween={10}
    //     slidesPerView={4}
    //     freeMode={true}
        
    //     watchSlidesProgress={true}
    //     modules={[FreeMode, Navigation, Thumbs]}
    //     className="mySwiper"
    //   >
    //     {
    //       isVariant ?
    //         product.variant[index].variantImages.map((item) => (
    //           <SwiperSlide>
    //             <img src={`${VMultiImgUrl}/${item.image}`} />
    //           </SwiperSlide>
    //         ))


    //         : product.productImages.map((item) => (
    //           <SwiperSlide>
    //             <img src={`${PMultiImgUrl}/${item.image}`} />
    //           </SwiperSlide>
    //         ))
    //     }
    //   </Swiper>
    // </>
  );
};

export default ProductImagesGallery;