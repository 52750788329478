import { useState, memo, useEffect, FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useApiRequest from "@mbs-dev/api-request";
import { BlogProps } from '@helpers/Props'
import { FrCustomeErrorNorify } from '@helpers/Toastify'
import { DecodeData, ReactHelmet, apiUrl, blogImgUrl } from '@helpers/Helpers'
import { loadingHelper } from '@helpers/Index'
import Layout from '../../../../layouts/Layout'
import './BlogDetails.css'

const BlogDetails: FC = () => {
    const { slug } = useParams()
    const { apiRequest } = useApiRequest();
    const [blog, setBlog] = useState<BlogProps>()
    const [isLoading, setIsLoading] = useState(false)

    const { state } = useLocation()


    const fetchData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `${apiUrl}/blogs.json?slug=${slug}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setBlog(response.data[0])
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (state) {
            setBlog(state)
        } else {
            fetchData()
        }
    }, [])


    return (

        <Layout>
            <ReactHelmet title={`Article - ${blog?.title}`} />
            {blog ?
                <div className="container mt-5 pt-4 d-flex-center">
                    <div className="row d-flex-center">
                        <div className="col-12 pb-3 clr-blue d-flex-center">
                            <h1 className='details-blog-title w-80 ff-varela-round fs-2'>
                                {blog.title}
                            </h1>
                        </div>
                        <div className="col-12 d-flex-center">
                            <img
                                src={`${blogImgUrl}/${blog.image}`}
                                alt={blog.title}
                                className='details-blog-img' />
                        </div>

                        <div className="col-12 w-80 mt-5 d-flex-center">
                            <DecodeData content={blog.description} />
                        </div>

                        <div className="col-12 mt-5 d-flex-center">
                            {blog.videoUrl ?
                                <div className="details-blog-video w-80">
                                    <div className="details-blog-video-container">
                                        <iframe
                                            id="player"
                                            src={blog.videoUrl.replace('watch?v=', 'embed/')}
                                            allowFullScreen
                                            title={blog.title}
                                        ></iframe>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                :
                <loadingHelper.AnimatedLoading label={`d'article`} />
            }

        </Layout>
    )
}

export default memo(BlogDetails)