import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiRequest from "@mbs-dev/api-request";
import { WishlistItemProps } from '@helpers/Props';
import { useWishlist } from '@contexts/WishlistContext';
import { useWishlistDataContext } from '@contexts/WishlistDataContext';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import { apiUrl, uploadUrl, variantIconUrl } from '@helpers/Helpers';
import './WishlistItem.css'

const WishlistItem: React.FC<WishlistItemProps> = ({ product, quantity, isvariant, variantId, price, itemKey }) => {

  const {
    removeFromWishlist,
    incrementWishlistQuantity,
    decrementWishlistQuantity,
    wishlistItems
  } = useWishlist()

  const { addToCart } = useShoppingCart()
  const { products, packs } = useWishlistDataContext();
  const [discount, setDiscount] = useState(null);
  const { apiRequest } = useApiRequest();
  const [variantPrice, setVariantPrice] = useState<number>();
  const [isVariant, setIsVariant] = useState(false);


  const mergedArray = [...products, ...packs];

  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchPromotionData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        setDiscount(response.data.discount)
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchPromotionData();
  }, [item?.id])

  let variantInfo: any
  if (item?.variant && item.variant.length > 0) {
    variantInfo = item.variant.find(i => i.id === variantId)
  }

  useEffect(() => {
    if (discount !== null && item?.variant && item?.variant?.length > 0) {
      let sellingPrice = variantInfo?.sellingPrice
      if (sellingPrice !== item?.sellingPrice) {
        setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
        setIsVariant(true)
      }
    }
  }, [])

  if (item == null) return null

  let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;


  return (
    <>
      <div className="wishlist-item d-flex-ai-center">
        <div className="container">
          <div className="row d-flex-center wishlist-item-content">
            <div className="col-lg-3 col-md-2 col-sm-5 col-5 d-flex-ai-center wishlist-item-image">
              <Link to={`${item.itemType === 'pack' ? `/boutique/pack/${item.slug}` : `/produit/${item.slug}`}`} className='d-inline-flex h-100'>
                <img src={`${uploadUrl}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="rounded wishlist-item-img" />
              </Link>
            </div>

            <div className="col-lg-6 col-md-5 col-sm-7 col-7 d-flex-ai-center">
              <div className="wishlist-item-details">
                <div className="wishlist-item-title mb-2 ff-varela-round fw-600">{item.title}</div>
                {isvariant ?
                  <div className="d-flex ai-center">
                    <div className={`subvariant-icon-content mx-1 d-flex-center c-pointer selected-border`} >
                      <div className="subvariant-icon d-flex-center">
                        <img src={`${variantIconUrl}/${variantInfo?.icon}`} alt={variantInfo?.label} className='variant-icon-img' />
                      </div>
                    </div>
                    <div className="subvariant-title ff-varela-round fw-600">
                      {`${variantInfo?.reference} ${variantInfo?.label}`}
                    </div>
                  </div>
                  :
                  null
                }
                {/* <div className="wishlist-item-prix clr-dark">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : productPrice.toFixed(2)} DH</div> */}
                <div className="wishlist-item-prix clr-dark">{price} DH</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-3 d-flex-ai-center">
              <div className="wishlist-item-action">
                <button
                  className='btn my-1 clr-info fw-600 ff-varela-round text-center wishlist-item-btn wishlist-item-addtocart'
                  onClick={() => {
                    item.itemType === 'product' ?
                      item.variant && item.variant?.length > 0 ?
                        addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', variantId, 1, item.sellingPrice, true)
                        :
                        addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', '', 1, item.sellingPrice)
                      :
                      addToCart(item.uniqId, item.sellingPrice, 'pack')
                  }}>
                  Ajouter au panier
                </button>
                <button
                  className='btn my-1 clr-pink fw-600 ff-varela-round text-center wishlist-item-btn wishlist-item-delete'
                  // onClick={(() => removeFromWishlist(item.itemType === 'pack' ? item.uniqId : item.id))}
                  onClick={(() => removeFromWishlist(item.itemType === 'pack' ? item.uniqId : item.id, itemKey))}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

const MemoizedWishlistItem = memo(WishlistItem);

export { MemoizedWishlistItem as WishlistItem };