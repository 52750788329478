import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import { ShoppingCart } from '../pages/ShoppingCart/ShoppingCart';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import Contact from '../pages/Contact/Contact';
import { EditAccount } from '../pages/account/pages/UserEditAccount/EditAccount';
import { DashContent } from '../pages/account/pages/UserDashboard/DashContent';
import { UserOrders } from '../pages/account/pages/Orders/UserOrders';
import { EditAddress } from '../pages/account/pages/UserAdresse/EditAddress';
import { CodePromo } from '../pages/account/pages/CodePromo/CodePromo';
import AdresseFacturation from '../pages/account/pages/UserAdresse/pages/AdresseFacturation';
import AdresseLivraison from '../pages/account/pages/UserAdresse/pages/AdresseLivraison';
import TermsOfUse from '../pages/Terms/TermsOfUse';
import TermsOfSale from '../pages/Terms/TermsOfSale';
import { UserOrderDetails } from '../pages/account/pages/Orders/UserOrderDetails';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import { UserReviews } from '../pages/account/pages/UserReviews/UserReviews';
import Error404 from '../pages/Errors/Error404';
import DetailsCategories from '../pages/DetailsCategories/DetailsCategories';
import DetailsMarques from '../pages/DetailsMarques/DetailsMarques';
import CmiOkPage from '../pages/Payment/CmiOkPage';
import CmiFailPage from '../pages/Payment/CmiFailPage';
import Wishlist from '../pages/Wishlist/Wishlist';
import { BestSellerBoutique, Boutique, PackBoutique, PackDetails, PromotionBoutique } from './Lazy';
import Checkout from '../pages/Checkout/Checkout';
import LatestBoutique from '../pages/Boutique/Pages/Latest/LatestBoutique';
import BlogsList from '../pages/Blog/Pages/BlogsList/BlogsList';
import BlogDetails from '../pages/Blog/Pages/BlogDetails/BlogDetails';
import ProductDetails from '../pages/ProductDetails/ProductDetails';
import AboutUs from '../pages/AboutUs/AboutUs';
import TermsOfShipping from '../pages/Terms/TermsOfShipping';
import { AntiAuthCheckoutGuard } from './RoutingGuard';
import AntiAuthCheckout from '../pages/Checkout/AntiAuthCheckout';
import OfflineOrder from '../pages/OfflineOrder/OfflineOrder';
import Loading from '@components/Loading/Loading';

const Routing: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, []);

  return (
    <Router basename="/">
      {isLoading ?
        <Loading />
        :
        <Routes>
          {/* -------- Global */}
          <Route path="*" element={<Error404 />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<AboutUs />} />
          {/* <Route path="/conditions-generales-dutilisation" element={<TermsOfUse />} /> */}
          <Route path="/conditions-generales" element={<TermsOfSale />} />
          <Route path="/livraison" element={<TermsOfShipping />} />
          {/* <Route path="/livraison" element={<TermsOfShipping />} /> */}

          {/* -------- Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />

          {/* -------- Account */}
          <Route path="/my-account" element={<DashContent />} />
          <Route path="/my-account/edit-account" element={<EditAccount />} />
          <Route path="/my-account/orders" element={<UserOrders />} />
          <Route path="/my-account/orders/order-details/:id" element={<UserOrderDetails />} />
          <Route path="/my-account/edit-address" element={<EditAddress />} />
          <Route path="/my-account/code-promo" element={<CodePromo />} />
          <Route path="/my-account/reviews" element={<UserReviews />} />
          <Route path="/my-account/edit-address/facturation" element={<AdresseFacturation />} />
          <Route path="/my-account/edit-address/livraison" element={<AdresseLivraison />} />
          <Route path="/my-account/edit-address/livraison/:adresseId" element={<AdresseLivraison />} />
          <Route path="/my-account/edit-address/facturation/:adresseId" element={<AdresseFacturation />} />

          {/* -------- Boutique */}
          <Route path="/boutique" element={<Boutique />} />
          <Route path="/boutique/categorie/:categorie" element={<Boutique />} />
          <Route path="/boutique/categorie/:categorie/:subCategorie" element={<Boutique />} />
          <Route path="/boutique/marque/:marque" element={<Boutique />} />

          {/* -------- Best Seller */}
          <Route path="/boutique/best-seller" element={<BestSellerBoutique />} />
          <Route path="/boutique/best-seller/marque/:marque" element={<BestSellerBoutique />} />
          <Route path="/boutique/best-seller/categorie/:categorie" element={<BestSellerBoutique />} />
          <Route path="/boutique/best-seller/categorie/:categorie/:subCategorie" element={<BestSellerBoutique />} />

          {/* -------- Promotion */}
          <Route path="/boutique/promotion" element={<PromotionBoutique />} />
          <Route path="/boutique/promotion/marque/:marque" element={<PromotionBoutique />} />
          <Route path="/boutique/promotion/categorie/:categorie" element={<PromotionBoutique />} />
          <Route path="/boutique/promotion/categorie/:categorie/:subCategorie" element={<PromotionBoutique />} />

          {/* -------- Latest */}
          <Route path="/boutique/latest" element={<LatestBoutique />} />
          <Route path="/boutique/latest/marque/:marque" element={<LatestBoutique />} />
          <Route path="/boutique/latest/categorie/:categorie" element={<LatestBoutique />} />
          <Route path="/boutique/latest/categorie/:categorie/:subCategorie" element={<LatestBoutique />} />

          {/* -------- Pack */}
          <Route path="/boutique/packs" element={<PackBoutique />} />
          <Route path="/boutique/pack/:slug" element={<PackDetails />} />

          {/* -------- Product */}
          <Route path="/produit/:slug" element={<ProductDetails />} />

          {/* -------- Cart/Order */}
          <Route path="/my-cart" element={<ShoppingCart />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/checkout" element={<Checkout />} />

          {/* -------- Offline Order */}
          <Route path="/check-offline-order/:uid" element={<OfflineOrder />} />
          {/* <Route path="/offline-order-details/:uid" element={<Checkout />} /> */}

          <Route
            path="/visitor-checkout"
            element={
              <AntiAuthCheckoutGuard>
                <AntiAuthCheckout />
              </AntiAuthCheckoutGuard>
            }
          />

          {/* -------- Blog */}
          <Route path="/blogs" element={<BlogsList />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />

          {/* -------- Details| C/M */}
          <Route path="/details-categories" element={<DetailsCategories />} />
          <Route path="/details-marques" element={<DetailsMarques />} />

          {/* -------- Payment */}
          <Route path="/payments/cmi/ok" element={<CmiOkPage />} />
          <Route path="/payments/cmi/fail" element={<CmiFailPage />} />
          <Route path="/anti-auth/payments/cmi/ok" element={<CmiOkPage />} />
          <Route path="/anti-auth/payments/cmi/fail" element={<CmiFailPage />} />
        </Routes>
      }
    </Router>
  );
};

export { Routing };