import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useMarquesContext } from '@contexts/MarquesContext';
import { marqueImgUrl } from '@helpers/Helpers';
import { HeaderContainer } from '@helpers/Index';
import Layout from '@layouts/Layout';
import './DetailsMarques.css'

const DetailsMarques: React.FC = () => {

    const { marques } = useMarquesContext();

    return (

        <Layout>
            <Helmet>
                <title>Marques</title>
            </Helmet>

            <HeaderContainer
                className="fw-bold mt-5 contact-title"
                title='Nos Marques'
            />

            <div className="container details-marques-container">
                <>
                    <div className="row details-marques-row d-flex-cente" >
                        {
                            marques?.map((item, index) => (

                                // <>
                                <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 p-2 mb-4 d-flex-center details-marques-col-img">
                                    <div className="details-marques-content p-0">
                                        <div className="details-marques-image px-2">
                                            <Link to={`/boutique/marque/${item.slug}`}>
                                                <img
                                                    src={`${marqueImgUrl}/${item.image}`}
                                                    alt={item.title}
                                                    className='details-marques-img mt-2' />
                                            </Link>
                                        </div>

                                        <Link to={`/boutique/marque/${item.slug}`} className="bm-link c-pointer details-marques-title d-flex-center">
                                            <h5 className='top-image-desc ff-outfit-m fw-600 text-center c-pointer'>{item.title}</h5>
                                        </Link>
                                    </div>
                                </div>

                                // {/* </> */}
                            ))
                        }

                    </div>

                    {/* <hr className='hr-tag' /> */}
                </>
            </div>
        </Layout>
    );
};

export default DetailsMarques;
