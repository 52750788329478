import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeaderContainer } from '@helpers/Index';
import Layout from '../../layouts/Layout';

const TermsOfShipping: React.FC = () => {

    return (
        <Layout>
            <Helmet>
                <title>Beauty Hub - Livraison</title>
            </Helmet>
            <HeaderContainer
                className="ff-lato-bold fs-1 mt-5 "
                title='Livraison'
            />

            <div className="container">
                <div className="row d-flex-start">
                    <div className="col-12 terms">
                        <p>
                            <p>Dès confirmation de votre commande, vous recevrez un email confirmant la préparation de votre colis.</p>

                            <p>Votre commande sera ensuite prise en charge par l’un de nos transporteurs partenaires et livrée à l’adresse de votre choix (domicile, bureau…), partout au Maroc.</p>

                            <p>Les délais de livraison varient entre 2 et 4 jours ouvrés selon la ville de destination.</p>

                            <p>Vous pouvez suivre l’avancement de votre livraison en nous écrivant à l’adresse Hello@beautyhub.ma.</p>

                            <p>Les frais de livraison s’élèvent à 40 Dhs TTC.</p>

                            <p>La livraison est offerte pour toute commande dont le montant est supérieur à 300 Dhs TTC.</p>
                        </p>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default TermsOfShipping;
