import { FC, useEffect, useState } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { ProductProps } from '@helpers/Props';
import { HeaderContainer, formHelper, hooks } from '@helpers/Index';
import ProductCard from '../ProductCard/ProductCard';
import { apiUrl } from '@helpers/Helpers';
import ProductSwiper from '../Carousel/Product/ProductItemMultiCarousel/ProductSwiper';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

const PopulareProducts: FC = () => {
  // const [products, setProducts] = useState<ProductProps[]>([]);
  const { productIds, isProductIds } = hooks.useProductIds();
  const { apiRequest } = useApiRequest();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/populare-products/8`,
        method: 'GET',
      });

      if (response.status === 200) {
        // setProducts(response.data)
      }
    } catch (error) {

    }
  };



  const {
    data,
    isLoading: isFetching,
  } = useQuery<ProductProps[], Error>(
    'PopulareProducts',
    async () => {
      const response = await apiRequest({
        route: `${apiUrl}/populare-products/8`,
        method: 'GET',
      });
      return response.data;

    }
  );

  const products = data || []


  const fetchProductsData = async () => {
    try {
      const response = await formHelper.axios.get(`${apiUrl}/products.json`, {
        params: {
          disponibilite: 'enstock',
          bestseller: 'enable',
          page: 1,
          itemsPerPage: 8,
        },
      })

      if (response.status === 200) {
        // setProducts(response.data)
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    // fetchProductsData();
    // fetchOfferData()
  }, [])


  return (
    <>
      {products.length > 0 ?
        <div className="productss home-container populare-products">
          <div className="container">
            <HeaderContainer className="fw-bold  mt-5 pt-5 fs-37px ff-outfit-m" title="MEILLEURES VENTES" />
            <div className="row justify-content-center">
              {/* {products.map((item) => (
                <ProductCard key={item.id} item={item} productIdsObj={{ productIds, isProductIds }} />
              ))} */}
              <ProductSwiper items={products} itemType='product' />
            </div>

            <div className="d-flex justify-content-center">
              <Link to='/boutique/best-seller' className="btn-loading mt-2 p-0 d-flex-center" >
                Voir plus
              </Link>
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  );
};

export default PopulareProducts;