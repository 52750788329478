import { useProductInfo } from '@hooks/useProductInfo';
import { useProductIds } from '@hooks/useProductIds';
import useClickInside from '@hooks/useClickInside';
import useClickOutside from '@hooks/useClickOutside';

import { useFormik } from "formik";
import FormInput from '@components/FormInput/FormInput';
import SubmitButton from '@components/SubmitButton/SubmitButton';
import axios from 'axios';

import AnimatedLoading from '@components/Loading/AnimatedLoading';
import LoadingMore from '@components/Loading/LoadingMore';
import LoadingData from '@components/Loading/LoadingData';
import DottedLoading from '@components/Loading/DottedLoading';
import ColoringLoading from '@components/Loading/ColoringLoading';
import LoadingWithDots from '@components/Loading/LoadingWithDots';

// Default Components
export { default as Navbar } from '@components/Navbar/Navbar';
export { default as TopHeader } from '@components/TopHeader/TopHeader';
export { default as Footer } from '@components/Footer/Footer';
export { default as HeaderContainer } from '@components/HeaderContainer/HeaderContainer';
export { default as FormInput } from '@components/FormInput/FormInput';
export { default as SubmitButton } from '@components/SubmitButton/SubmitButton';
export { default as ProductCard } from '@components/ProductCard/ProductCard';
export { default as PackCard } from '@components/PackCard/PackCard';

//  Components
export { ShoppingCartItem } from '@components/Cart/ShoppingCartItem/ShoppingCartItem';

// Images
export { default as checkoutImage } from '../../assets/images/checkout/promocode.png'
export { default as mastercard } from '../../assets/images/checkout/master.png'
export { default as visacard } from '../../assets/images/checkout/visa.png'
export { default as cash } from '../../assets/images/checkout/cash.png'
export { default as facebook } from '../../assets/images/login/facebook-icon.png'
export { default as emptyBox } from '../../assets/images/bootique/empty-box.png'
export { default as socialMedia } from '../../assets/images/social/social-media-1.png'
export { default as emptyCart } from '../../assets/images/checkout/empty-cart.png'
export { default as freeShipping } from '../../assets/images/shipping/free-shipping.png'
export { default as emptyWishlist } from '../../assets/images/wishlist/empty-wishlist.png'
export { default as logo } from '../../assets/images/logo/logo-beautyhub.png';


export const hooks = {
    useProductInfo,
    useProductIds,
    useClickInside,
    useClickOutside
};

export const formHelper = {
    useFormik,
    FormInput,
    SubmitButton,
    axios,
};

export const loadingHelper = {
    AnimatedLoading,
    LoadingMore,
    LoadingData,
    DottedLoading,
    ColoringLoading,
    LoadingWithDots
};
