import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocalStorage } from "@hooks/useLocalStorage"
import { toast } from 'react-toastify';
import { InfoNotify } from "../helpers/Toastify";
import { useNavigate } from "react-router-dom";
import { useProductIds } from "@hooks/useProductIds";

type WishlistProviderProps = {
  children: ReactNode
}

type WishlistItem = {
  product: number | string,
  // pack?: number | string
  quantity: number,
  price: number,
  originPrice?: number,
  itemtype?: string
  isvariant?: boolean
  variant?: number | string,
  ukey?: number | string,
}

interface OrderContextType {
  paymentMethod: string;
  totalPrice: number;
}

type WishlistContext = {
  getItemQuantity: (product: number | string) => number
  incrementWishlistQuantity: (user: number | string | undefined, product: number | string, price: number) => void
  decrementWishlistQuantity: (user: number | string | undefined, product: number | string) => void
  removeFromWishlist: (product: number | string, itemKey: number | string | undefined) => void
  addToWishlist: (user: number | string | undefined, product: number | string, price: number, itemtype?: string, variant?: number | string, quantity?: number, originPrice?: number, isvariant?: boolean) => void
  emptyWishlist: () => void;
  wishlistQuantity: number
  wishlistItems: WishlistItem[],
  sousTotal: number;
  orderData: OrderContextType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderContextType>>;
  updateWishlistItemsWithPromotions: () => void

}


const WishlistContext = createContext({} as WishlistContext)

export function useWishlist() {
  return useContext(WishlistContext)
}

export function WishlistProvider({ children }: WishlistProviderProps) {
  const [wishlistItems, setWishlistItems] = useLocalStorage<WishlistItem[]>(
    "wishlist",
    []
  )
  const [orderData, setOrderData] = useState<OrderContextType>({
    paymentMethod: '',
    totalPrice: 0,
  });

  const wishlistQuantity = wishlistItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const { productIds, isProductIds } = useProductIds();
  const [sousTotal, setSousTotal] = useState(0);

  useEffect(() => {
    const newTotal = wishlistItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );
    setSousTotal(newTotal);
  }, [wishlistItems]);


  const notifySuccess = (entity: string) => {
    toast.success(`${entity} ajouté avec succès`);
  };

  const notifyRemove = (entity: string) => {
    toast.success(`${entity} supprimé avec succès`);
  };


  function getItemQuantity(product: number | string) {
    return wishlistItems.find(item => item.product === product)?.quantity || 0
  }

  function addToWishlist(user: number | string | undefined, product: number | string, price: number, itemtype?: string, variant?: number | string, quantity: number = 1, originPrice?: number, isvariant?: boolean) {
    setWishlistItems((currItems) => {
      const ukey = `${product}-${variant || ''}`;
      const existingItem = currItems.find((item) => item.ukey === ukey);

      if (existingItem) {
        return currItems.map((item) =>
          item.ukey === ukey
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        if (variant) {
          return [
            ...currItems,
            { user, product, price, itemtype, quantity, originPrice, variant, ukey, isvariant },
          ];
        } else {
          return [
            ...currItems,
            { user, product, price, itemtype, quantity, originPrice, ukey },
          ];
        }
      }
    });
    notifySuccess(
      `${typeof product === "string" ? "Pack" : "Produit"}`
    );
  }


  function incrementWishlistQuantity(user: number | string | undefined, product: number | string, price: number) {
    setWishlistItems(currItems => {
      if (currItems.find(item => item.product === product) == null) {
        return [...currItems, { user, product, quantity: 1, price }]
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decrementWishlistQuantity(user: number | string | undefined, product: number | string) {
    setWishlistItems(currItems => {
      if (currItems.find(item => item.product === product)?.quantity === 1) {
        return currItems.filter(item => item.product !== product)
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function removeFromWishlist(product: number | string, itemKey: number | string | undefined) {
    const pType = typeof (product);
    setWishlistItems(currItems => {
      return currItems.filter(item => item.ukey !== itemKey)
    })
    notifyRemove(`${pType === 'string' ? 'Pack' : 'Produit'}`);
  }

  function emptyWishlist() {
    setWishlistItems([]);
  }

  const updateWishlistItemsWithPromotions = () => {
    if (isProductIds) {
      const updatedCartItems = wishlistItems.map((item) => {
        const productHasPromotion = productIds.includes(Number(item.product));
        if (!productHasPromotion && item.itemtype === 'product') {
          return {
            ...item,
            price: item.originPrice || item.price,
          };
        }
        return item;
      });
      setWishlistItems(updatedCartItems);
    }
  };

  return (
    <WishlistContext.Provider
      value={{
        getItemQuantity,
        addToWishlist,
        incrementWishlistQuantity,
        decrementWishlistQuantity,
        removeFromWishlist,
        emptyWishlist,
        wishlistItems,
        wishlistQuantity,
        sousTotal,
        orderData,
        setOrderData,
        updateWishlistItemsWithPromotions
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}