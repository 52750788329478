import { Rating } from "react-simple-star-rating";
import { NavLinkProps, ReviewsProps } from "./Props";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import React from "react";
import { InfoNotify } from "./Toastify";

interface TruncateTextProps {
    text: string
    maxLength: number;
}

interface DecodeDataProps {
    className?: string;
    content: string
}

interface CustomeRatingProps {
    initValue: number;
    readonly: boolean;
    onClick?: (rate: number) => void;
}

interface HelmetProps {
    title: string;
}

export function createFormData(data: any, files?: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });
    if (files) {
        files.map((file: any) => {
            formData.append("images[]", file)
        })
    }

    return formData;
}

const encodeHtmlAssociations: { [key: string]: string } = {
    ["<"]: "@lt",
    [">"]: "@gt",
}
const decodeHtmlAssociations: { [key: string]: string } = {
    ["@lt"]: "<",
    ["@gt"]: ">",
}
export function encodeHtmlTags(html: string): string {
    Object.keys(encodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), encodeHtmlAssociations[key]);
    });
    return html
}

export function decodeHtmlTags(html: any): any {
    Object.keys(decodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), decodeHtmlAssociations[key]);
    });
    return html
}

export const htmlDecode = (input: string): string => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || '';
};

export const getMoneyAmount = (amount: number) => {
    return `${amount} EUR`;
};

export const getUserPhoto = (photo: any) => {
    return photo
        ? `${process.env.NEXT_PUBLIC_STORAGE_ROUTE}/${photo}`
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
};

export const getRangeYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    let startYear = currentYear - 50;

    while (startYear <= currentYear + 50) {
        years.push(startYear++);
    }

    return years;
};


export function UFormatDate(dateString: string | Date): string | Date {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
}

export function FormatDate(dateString: string | Date): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}


export function StringToDate(dateString: string): Date {
    return new Date(dateString);
}

export function getProductPrice(product: { tarif_promo: any; sellingPrice: any; date_de_debut_de_promo: any; date_de_fin_de_promo: any }) {
    // if (StringToDate(product.date_de_fin_de_promo) > new Date()){
    if (StringToDate(product.date_de_debut_de_promo) <= new Date() && StringToDate(product.date_de_fin_de_promo) > new Date()) {
        return product.tarif_promo !== null ? product.tarif_promo : product.sellingPrice;
    }
    else {
        return product.sellingPrice;

    }

}

export function XformatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}

export function formatDate(dateWithTimezone: string | any) {
    const dateWithoutTime = dateWithTimezone.slice(0, 10);
    const [year, month, day] = dateWithoutTime.split('-');
    return `${day}-${month}-${year}`;
}

export function DBFormatDate(date: Date | string | any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export function logOut() {
    localStorage.removeItem('bh_user_token');
    localStorage.removeItem('bh_user');
}

export const referenceGeneratorOld = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = parseInt(String(currentDate.getDate()), 10);
    // const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const randomNumber = Math.floor(Math.random() * 99) + 1;

    const reference = `${randomNumber}${seconds}${day}${hours}${minutes}`;
    return reference;
};

export const referenceGenerator = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear()).slice(-2);
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const randomNumber = "000";

    const reference = `${year}${month}${randomNumber}`;
    return reference;
};

export const orderReferenceGenerator = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear()).slice(-2);
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const randomNumber = "00";

    const reference = `${year}${month}${randomNumber}`;
    return reference;
};

export const handleImageLinkDrage = (e: any) => {
    e.preventDefault();
};

export function formatPrice(price: string): string {
    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
        return price;
    }

    return numericPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).replace(',', ' ');
}

export function formatDatex(inputDate: string): string {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
}

export function TruncateText({ text, maxLength }: TruncateTextProps) {
    if (text.length <= maxLength) {
        return <span>{text}</span>;
    }

    const truncatedText = text.slice(0, maxLength) + '...';

    return <span>{truncatedText}</span>;
}

export function isEven(number: number): boolean {
    return number % 2 === 0;
}

export function DecodeData({ content, className }: DecodeDataProps) {
    return <span
        className={`product-details-desc ff-varela-round ${className}`}
        dangerouslySetInnerHTML={{ __html: decodeHtmlTags(content.replaceAll('\\n', "<br>")) }}>
    </span>
}

export function CustomeRating({ initValue, readonly, onClick }: CustomeRatingProps) {
    return <Rating
        initialValue={initValue}
        readonly={readonly}
        onClick={onClick}
        fillColorArray={[
            '#f14f45',
            '#f16c45',
            '#f18845',
            '#f1b345',
            '#f1d045'
        ]} />
}

export function calculateTotalRating(reviews: ReviewsProps[]): number {
    const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
    const rating = Math.floor(totalRating / reviews.length)
    return rating;
}

export const calculateReviewsByRating = (reviews: ReviewsProps[], ratingValue: number) => {
    const totalReviewsWithRating = reviews.filter(review => review.rating === ratingValue).length;
    return totalReviewsWithRating;
};

export const generateRandomKey = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
    let randomKey = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomKey += characters.charAt(randomIndex);
    }

    return randomKey;
}

export function ReactHelmet({ title }: HelmetProps) {
    return <Helmet>
        <title>{title}</title>
    </Helmet>
}

export const NavLink: React.FC<NavLinkProps> = ({ label, to }) => (
    <li className="nav-item ps-0">
        <Link className="text-upper nav-link navbar-link px-" to={to}>
            {label}
        </Link>
    </li>
);

export const FooterLink: React.FC<NavLinkProps> = ({ label, to }) => (
    <p>
        <Link to={to} className='ff-lato-reg nav-link textpink-hover'>
            {label}
        </Link>
    </p>
);

export const handleScrollTop = () => {
    document.documentElement.scrollTop = 0;
};

export function valueText(value: number) {
    return `${value}`;
}

export const paysRegion = 'Maroc'

export const pendingGoogle = () => {
    InfoNotify('La connexion avec Google est en cours de maintenance.')
};

export const JSONHeader = {
    accept: 'application/json',
    'Content-Type': 'application/json',
}
export const BearerHeader = {
    Authorization: `Bearer ${localStorage.getItem('bh_user_token')}`
}


export const REACT_APP_FB_APP_ID = '3443858605865162';
export const REACT_APP_GG_APP_ID = '324294602884-jad235obqifn3pm5ecmgnv7h752v89pu.apps.googleusercontent.com';
// export const REACT_APP_GG_APP_ID = '335256070974-glu2bmdo4bvre2f8btdticj8pibiuufo.apps.googleusercontent.com';

export const appUrl = process.env.REACT_APP_URL
export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD

export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'

export const productImgUrl = `${uploadUrl}/product_images`
export const PMultiImgUrl = `${uploadUrl}/product_multi_images`
export const variantImgUrl = `${uploadUrl}/variant_images`
export const variantIconUrl = `${uploadUrl}/variant_icons`
export const VMultiImgUrl = `${uploadUrl}/variant_multi_images`
export const packImgUrl = `${uploadUrl}/pack_images`
export const marqueLogoUrl = `${uploadUrl}/marque_logos`
export const marqueImgUrl = `${uploadUrl}/marque_images`
export const categorieImgUrl = `${uploadUrl}/category_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`
export const specialOffersImgUrl = `${uploadUrl}/offer_images`
export const blogImgUrl = `${uploadUrl}/blog_images`
export const blogMultiImgUrl = `${uploadUrl}/blog_multi_images`
export const blogPostImgUrl = `${uploadUrl}/blog_post_images`

