import { ProductProps } from "../../helpers/Props";

export function applyFiltersAndSort(
    data: ProductProps[],
    filteredPrice: number[],
    sortBy: string,
    sortByDate: string | undefined
): ProductProps[] {
    let filteredData = [...data];

    filteredData = filteredData.filter((product) => {
        const price = product?.sellingPrice;
        return price >= filteredPrice[0] && price <= filteredPrice[1];
    });

    if (sortBy === 'prix-croissant') {
        filteredData = filteredData.sort((a, b) => a.sellingPrice - b.sellingPrice);
    } else if (sortBy === 'prix-decroissant') {
        filteredData = filteredData.sort((a, b) => b.sellingPrice - a.sellingPrice);
    }

    if (sortBy === 'date-asc' || sortBy === 'date-desc') {
        filteredData = filteredData.sort((a, b) => {
            const dateA = new Date(a.creationDate) as any;
            const dateB = new Date(b.creationDate) as any;

            if (sortByDate === 'asc') {
                return dateA - dateB;
            } else if (sortByDate === 'desc') {
                return dateB - dateA;
            }

            return 0;
        });
    }

    return filteredData;
}

export function handleSortChange(
    selectedSortOption: string,
    setSortBy: React.Dispatch<React.SetStateAction<string>>,
    setSortByDate: React.Dispatch<React.SetStateAction<string>>,
    setIsFilter: React.Dispatch<React.SetStateAction<boolean>>
): void {
    setSortBy(selectedSortOption);

    if (selectedSortOption === 'date-asc') {
        setSortByDate('asc');
    } else if (selectedSortOption === 'date-desc') {
        setSortByDate('desc');
    }

    setIsFilter(true);
}
