import { FC, useEffect, useState } from 'react';
import useApiRequest from "@mbs-dev/api-request";
import { PackProps } from '@helpers/Props';
import { Link } from 'react-router-dom';
import PackCard from '../PackCard/PackCard';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import ProductSwiper from '../Carousel/Product/ProductItemMultiCarousel/ProductSwiper';
import { apiUrl } from '@helpers/Helpers';

const PopularePacks: FC = () => {
  const [packs, setPacks] = useState<PackProps[]>([]);
  const { apiRequest } = useApiRequest();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/populare-packs/8`,
        method: 'GET',
      });
      setPacks(response.data)

    } catch (error) {

    }
  };

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      {packs.length > 0 ?
        <div className="productss pack-container home-container mt-6">
          <div className="container">
            <HeaderContainer className="fw-bold mt-5 pt-5 fs-37px ff-outfit-semi" title="MEILLEURES PACKS" />
            <div className="row justify-content-center">
              <ProductSwiper items={packs} itemType='pack' />
            </div>
            <div className="d-flex justify-content-center">
              <Link to='/boutique/packs' className="btn-loading mt-2 p-0 d-flex-center" >
                explorer Les Packs
              </Link>
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  );
};

export default PopularePacks;